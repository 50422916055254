@import "./mixins";
#clock-section {
  #clock {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .clock-item {
      width:20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      margin: 15px;
      text-align: center;
      position: relative;
      padding: 70px 0px;
      @include my_bp(sm) {
        width: 15%;
      }
      span {
        display: inline-block;
        font-size: 30px;
      }
      .smalltext {
        font-size: 25px;
      }
      .circular-chart {
        display: block;
        margin: 10px auto;
        max-width: 100%;
        max-height: 250px;
        position: absolute;
      }
      .circle {
        stroke: #3813c2;
        fill: none;
        stroke-width: 1.8;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
      }
      .circle-bg {
        stroke: #ccc;
        fill: none;
        stroke-width: 1.8;
      }
    }
  }
}

.last-date {
  position: absolute;
  // top: 100px;
  width: 25%;
  z-index: 999;
  top:20%;
  right: 25px;
  
  h3{
    font-size: 1em;
  }
  img{
    max-width: 100%;
  }
}

.last-date {
  @include my_bp(sm) {
  position: absolute;
  // top: 100px;
  width: 25%;
  z-index: 999;
  right: 25px;
  top:45%;
  bottom: 25px;
  h3{
    font-size: 2em;
  }
  img{
    max-width: 100%;
  }
}
}

#about-section {
  .button {
    display: block;
    width: 250px;
    padding: 5px 10px;
    text-align: center;
    border-radius: 50px;
    border: 1px #3813c2 solid;
    color: #3813c2;
    transition: all .3s ease-in;
    margin: 0 auto;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      background: #3813c2;
      color: white;
    }
  }
  ul {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  li {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 15px rgba(200, 200, 200, .5);
    margin: 10px;
    transition: all .1s ease;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
    background: linear-gradient(45deg, #0075ee 0%, #00adee 50%);
    display: flex;
    flex: 1 1 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    @include my_bp(sm) {
      flex: 1 1 30%;
    }
    &:before {
      content: '';
      display: table;
      padding-top: 30%;
    }
    p {
      font-family: 'Libre Baskerville', times;
      font-weight: 700;
      font-size: 2.5rem;
    }
    // &:nth-of-type(1){
    //   background: #6ebf45;
    // }
    // &:nth-of-type(2){
    //   background: #d27fdf;
    // }
    // &:nth-of-type(3){
    //   background: #f3827f;
    // }
    // &:nth-of-type(4){
    //   background:  #ffbc57;
    // }
    // &:nth-of-type(5){
    //   background: #df58f5;
    // }
    // &:nth-of-type(6){
    //   background: #ff692f;
    // }
    &:hover {
      transform: scale(1.05);
    }
  }
}

#benefits {
  .card {
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 15px rgba(200, 200, 200, .4);
    h3 {
      margin-bottom: 20px;
      padding: 10px 0px 20px 0px;
      border-bottom: 2px solid #3813c2;
    }
  }
}

#register-section {
  .register-container {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 40px;
    .register-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0 0 10px rgba(200, 200, 200, .4));
      margin: 0 10px 0 10px;
      @include my_bp(sm) {
        width: 30%;
      }
      .top {
        padding-top: 50px;
        margin-bottom: -1px;
        border-bottom: 1px solid rgba(0, 0, 0, .4);
        background: #ffffff;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100% - 12px));
        .title {
          padding-bottom: 20px;
          h3 {
            text-align: center;
          }
        }
      }
      .bottom {
        height: 100%;
        background: #ffffff;
        clip-path: polygon(10px 0, calc(100% - 10px) 0, 100% 12px, 100% 100%, 0 100%, 0 12px);
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        text-align: center;
        p {
          padding: 30px 20px 10px;
        }
      }
      .down {
        background: #ffffff;
        text-align: center;
        .button {
          background: #3813c2;
          display: block;
          margin: 20px 30px 25px;
          padding: 15px;
          color: white;
          &:hover {
            background: #f20487;
            text-decoration: none;
          }
        }
      }
    }
  }
}

#speaker-section,
#investor-section {
  .speakers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include my_bp(sm) {
      justify-content: start;
    }
    .speaker-item {
      width: 45%;
      padding: 10px;
      @include my_bp(sm) {
        width: 25%;
      }
      .speaker {
        img {
          max-width: 100%;
          transition: all .1s ease-in;
          filter: grayscale(0%);
          cursor: pointer;
          // &:hover {
          //   filter: grayscale(0%);
          // }
        }
        .desc {
          h4 {
            margin-top: 10px;
            text-align: center;
          }
          p {
            font-size: 12px;
            margin-bottom: 0px;
            text-align: center;
          }
        }
      }
    }
  }
}

#count-section {
  background: #3813c2;
  .count {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    .cell {
      flex: 0 0 80%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      padding: 0.5rem;
      box-sizing: border-box;
      @include my_bp(sm) {
        flex: 0 0 30%;
      }
      &:before {
        content: '';
        display: table;
        padding-top: 100%;
      }
      .item {
        flex-grow: 1;
        background: #3813c2;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        i {
          font-size: 40px;
        }
        span {
          font-size: 40px;
        }
        h3 {
          color: white;
          text-align: center;
        }
      }
    }
  }
}

#gallery-section {
  padding: 50px 30px;
  background-color: #222;
  text-align: center;
  label[for] {
    cursor: pointer;
  }
  input[type="checkbox"] {
    display: none;
  }
  .lightbox {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    z-index: 1;
    overflow: auto;
    transform: scale(0);
    transition: transform .75s ease-out;
    img {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 96%;
      max-height: 96%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    }
  }
  input[type="checkbox"] {
    &:checked {
      &+.lightbox {
        transform: scale(1);
      }
      &~.grid {
        opacity: .25;
      }
    }
  }
  .grid {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #222;
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 16px;
    overflow: auto;
    text-align: center;
    transition: opacity .75s;
    margin-bottom: 25px;
    .grid-item {
      width: 100%;
      display: inline-block;
      padding: 16px;
      opacity: .6;
      transition: opacity .5s;
      &:hover {
        opacity: 1;
      }
      @include my_bp(sm) {
        width: 50%;
      }
      @include my_bp(lg) {
        width: 25%;
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    }
  }
  .button {
    border: 1px solid white;
    padding: 10px 15px;
    border-radius: 50px;
    color: white;
    transition: all 1s ease;
    &:hover {
      text-decoration: none;
      background: white;
      color: #222;
    }
  }
}

#host-section {
  padding-top: 60px;
  .hosts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    .cell {
      flex: 0 0 75%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      padding: 0.5rem;
      box-sizing: border-box;
      @include my_bp(sm) {
        flex: 0 0 25%;
      }
      &:before {
        content: '';
        display: table;
        padding-top: 100%;
      }
      .item {
        flex-grow: 1;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        i {
          font-size: 40px;
        }
        span {
          font-size: 40px;
        }
        h3 {
          color: white;
          text-align: center;
        }
      }
    }
  }
}

#partners-section {
  background: #18053d;
  h2 {
    color: white;
  }
  h4 {
    color: white;
    margin-top: 40px;
    margin-top: 10px;
    font-family: 'Blinker', sans-serif;
    text-align: center;
  }
  #partners {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    .partner-cover {
      width: 50%;
      padding: 5px;
      cursor: pointer;
      position: relative;
      display: flex;
      @include my_bp(sm) {
        width: 25%;
        min-width: 277px;
      }
      .partner-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin: 0px 0px 0px 0px;
        padding: 10px 5px 10px 5px;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: rgba(37, 37, 37, 0.35);
        border-radius: 5px 5px 5px 5px;
        background-color: #ffffff;
        transition: all .3s ease-in-out;
        &:hover {
          border-color: rgba(37, 37, 37, 0.5);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.24);
          h3 {
            color: #00aef0;
          }
        }
        .logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        h3 {
          font-family: 'Blinker', sans-serif;
          font-size: 14px;
          text-align: center;
          // margin-top: 10px;
          transition: all .3s ease-in-out;
        }
      }
    }
  }
}

#video-slider {
  height: 80vh;
  position: relative;
  .info-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      background: white;
      padding: .5rem 1rem;
    }
    h1 {
      color: white;
      font-size: 6rem;
    }
    p {
      color: white;
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #013367;
    opacity: .6;
    backdrop-filter: blur(5px);
  }
  .register-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3rem;
  }
}

.home-banner .slide .video-slide {
  background-color: #000;
}

.home-banner.loading .video-background {
  opacity: 0;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 0;
  padding-top: 56.25%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 2s opacity ease;
  transition: 2s opacity ease;
  opacity: 1;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.popover__wrapper {
  position: relative;
  margin-top: 1.5rem;
  display: inline-block;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -150px;
  transform: translate(0, 10px);
  background-color: #18053d;
  color: white;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
  font-size: 1.5rem;
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #18053d transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.kochi{
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
}

.vertical-center-data {
  min-height: 100%;  
  min-height: 100vh; 
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;


}